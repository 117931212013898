import * as React from "react";
import { SquareButtons } from "../components/common/ui";
import { Heading4 } from "../components/common/ntmwd";
import TwoColumnLayout from "../components/layout/TwoColumnLayout";
import Sidebar from "../components/layout/Sidebar/Sidebar";
import { FirebaseContext } from "../components/Firebase";
import AuthCheck from "../components/Firebase/AuthCheck";
import PortalHero from "../components/common/ntmwd/layout/PortalHero";
import { WmkSeo } from "wmk-seo";
import IEFallback from "../components/layout/Fallback/IEFallback";
import { colors } from "../vars/palette";
import { graphql } from "gatsby";
import { GatsbyImageData, Img } from "wmk-image";

const IndexPage = ({ data }: PortalHeroQuery) => {
  const hero = new Img(data.hero.mainImage);
  hero.addCrop("ogImage", data.hero.mainImage.ogImage);
  hero.addCrop("twitterImage", data.hero.mainImage.twitterImage);
  return (
    <>
      <WmkSeo.Meta
        title="Board Resources"
        siteTitle="NTMWD Board Portal"
        slug={"/"}
        baseUrl="https://board.ntmwd.com"
        twitterImageUrl={hero.url("twitterImage")}
        twitterHandle={''}
        ogImageUrl={hero.url("ogImage")}
      />
      <FirebaseContext.Consumer>
        {({ user }) => {
          return user ? (
            <IEFallback>
              <PortalHero hero={hero}>Board Resources</PortalHero>
              <TwoColumnLayout
                Main={() => (
                  <>
                    <Heading4
                      style={{
                        color: colors.hex("primary"),
                        margin: "5vh 0 8vh 0",
                      }}
                    >
                      Welcome to the NTMWD Board Resources Page
                    </Heading4>
                    <SquareButtons />
                  </>
                )}
                Sidebar={() => <Sidebar notices />}
              />
            </IEFallback>
          ) : (
            <AuthCheck />
          );
        }}
      </FirebaseContext.Consumer>
    </>
  );
};
export default IndexPage;

export interface PortalHeroQuery {
  data: {
    hero: {
      globalId: string;
      mainImage: {
        gatsbyImageData: GatsbyImageData;
        ogImage: GatsbyImageData;
        twitterImage: GatsbyImageData;
        title: string;
        file: {
          url: string;
          contentType: string;
        };
      };
    };
  };
}

export const query = graphql`
  {
    hero: contentfulSettingsGlobal(title: { eq: "Portal Hero" }) {
      globalId: title
      mainImage {
        gatsbyImageData
        ogImage: gatsbyImageData(width: 1200, height: 630, quality: 80)
        twitterImage: gatsbyImageData(width: 876, height: 438, quality: 80)
        title
        file {
          url
          contentType
        }
      }
    }
  }
`;
